.menu {
  position: relative;
  width: 100%;
  z-index: 1;
}
.logo {
  max-width: 10%;
  margin-left: 20px;
  max-height: 50px;
  min-height: 34px;
  min-width: 146px;
}

@media screen and (min-width: 991px) {
  .navbar-collapse {
    flex-basis: unset !important;
    flex-grow: unset !important;
    align-items: unset !important;
  }
}
.nav-link a {
  text-decoration: none;
  color: black;
  float: right;
}
