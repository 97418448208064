.about-text-container-width {
  width: 80%;
  margin: auto;
}

.mt-6 {
  margin-top: 60px;
}

.center {
  margin: auto;
  width: 100%;
  padding: 10px;
}
p {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 20px;
}
.image-position {
  width: 70%;
  margin: auto;
}
