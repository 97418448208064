.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.container {
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Playfair Display";
  letter-spacing: 5px;
}

@media (max-width: 780px) {
  .text-container {
    display: none;
  }
}
@media (min-width: 780px) {
  .text-container {
    position: absolute;
    top: 18%;
    right: 0px;
    color: white;
  }
}
