.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
  background: grey;
}
.bg-color-custom {
  background-color: white;
}
