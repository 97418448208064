.bg-career {
  background-image: linear-gradient(0deg, #f0f2f5, #f0f2f5);
}

.card {
  margin: auto;
}

.card-body p {
  font-size: 18px;
}

.card-link {
  cursor: pointer;
}

.modal-close-link {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.ReactModal__Content--after-open {
  margin-top: 40px;
  margin-bottom: 40px;
}
