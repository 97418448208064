.border-right {
  border-right: 1px solid;
}
.contact p {  
  font-size: 18px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {
  .second-box{
    margin-top: 40px !important;
  }
}

