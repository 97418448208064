.center-footer {
  text-align: center;
}
.footer {
  background-color: #f0f1f3;
}

.footer-box p {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .footer{
    display: none;
  }
}